const projects = [
  {
    title: 'Hawaii Lava Zones',
    description:
      'The chances of lava flowing to your house in Hawaii greatly impacts its value. The government defined “Lava Zones” and gave the public access to a small PDF map of the zones. It is difficult to tell what zone a property is in without obtaining records from the county. Hawaii Lava Zones allows users to input an address and receive a LavaZone number with a pin on a map of where the address is located.',
    tech: ['React', 'Javascript', 'API', 'AXIOS', 'MaterialUI', 'GoogleMaps'],
    webUrl: 'https://hawaiilavazones.com/',
    gitHubUrl: 'https://github.com/IsaacVon/HawaiiLavaZones.com',
  },
  {
    title: 'Chung and Associates',
    description:
      'This was my first live website. It was built for a client who is a CPA that needed a simple and clean website.',
    tech: ['React', 'Javascript', 'MaterialUI'],
    webUrl: 'http://chungandassociates.com/',
    gitHubUrl: 'https://github.com/IsaacVon/ChungAndAssociates.com',
  },
  {
    title: 'What Grows',
    description:
      'I created this website because I could not find a tool get a list of “What Grows” in your area. The user will enter a zip code and WhatGrows returns a list of plants that will grow in that zip code. There are various filters that can be applied also. Users can create accounts to add favorite plants and notes.',
    tech: [
      'React',
      'Javascript',
      'Node',
      'Express',
      'MongoDB',
      'GCP',
      'Mongoose',
      'GoogleMaps',
      'API',
      'AXIOS',
      'JWT',
      'styledComponents',
    ],

    webUrl: 'https://www.whatgrows.org/',
    gitHubUrl: 'https://github.com/IsaacVon/WhatGrows.org',
  },
  {
    title: 'Done With It',
    description:
      'DoneWithIt is a project similar to the app OfferUp. It was built by me using React-Native. To view the app on your phone, you must have Expo installed. Click on Visit Site and then scan the QR code with your phone. Log in using the mock Account: isaachouseholder@gmail.com Password: password.',
    tech: ['ReactNative', 'Javascript', 'GCP', 'Expo', 'Formik', 'JWT'],
    webUrl:
      'https://expo.io/@isaacvon/projects/DoneWithIt?release-channel=staging',
    gitHubUrl: 'https://github.com/IsaacVon/Done-With-It',
  },
];

export default projects;
